<template>
  <form class="c-join-audience-menu d-grid gap-3" @submit.prevent="onClickContinue">
    <div class="form-floating">
      <input id="c-join-audience-menu-username" class="form-control" type="text" placeholder="Username" v-model="username">
      <label for="c-join-audience-menu-username">What's your username?</label>
    </div>
    <button type="button" class="btn btn-info" @click="onClickContinue">Continue as AUDIENCE<i>!</i></button>
  </form>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { store } from "../store.ts"
import { findContestant } from "../contestants.ts"

const emit = defineEmits(["continue"])

const username = ref("")

function onClickContinue() {
  const name = username.value.trim()
  if (name.length < 2) {
    alert("Sorry, that username is too short!")
    username.value = ""
    return
  }
  if (name.length > 30) {
    alert("Sorry, that username is too long!")
    username.value = ""
    return
  }
  if (findContestant(name) != null) {
    alert("Sorry, that username is not allowed!")
    username.value = ""
    return
  }

  store.username = name
  store.contestant = null
  store.type = "audience"

  emit("continue")
}
</script>
