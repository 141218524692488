<template>
  <BaseButtonMenu>
    <BuzzerButton @pressed="onPressed" />
  </BaseButtonMenu>
</template>

<script setup lang="ts">
import { socket } from '../store.ts'
import BaseButtonMenu from './BaseButtonMenu.vue'
import BuzzerButton from './BuzzerButton.vue'

function onPressed() {
  socket.send('BUZZER', {})
}
</script>
