<template>
  <div class="c-base-button-menu d-grid gap-3">
    <UsernameHeader/>
    <slot/>
  </div>
</template>

<script setup lang="ts">
import UsernameHeader from './UsernameHeader.vue'
</script>
