<template>
  <div class="c-username-header">
    <h3 class="text-center">
      <span>Welcome to the show,</span> <span
        class="badge"
        :class="{
          'text-bg-primary': store.type === 'contestant',
          'text-bg-info': store.type === 'audience',
        }"
      >{{ store.username }}<i>!</i></span>
    </h3>
  </div>
</template>

<script setup lang="ts">
import { store } from '../store.ts'
</script>
