import { reactive, watch } from 'vue'
import { foxid } from '../shared/foxid.ts'
import { Socket } from '../shared/socket.ts'
import { kv } from './kv.ts'

export interface Store {
  id: string | null
  type: 'audience' | 'contestant' | null
  username: string | null
  contestant: string | null
}

export const store = reactive<Store>({
  id: kv.get('id') || foxid(32),
  type: kv.get('type') || null,
  username: kv.get('username') || null,
  contestant: kv.get('contestant') || null,
})

for (const key of ['id', 'type', 'username', 'contestant'] as (keyof Store)[]) {
  watch(() => store[key], (value) => kv.set(key, value), { immediate: true })
}

export let socket: Socket | null = null

watch(() => store.type, (type) => {
  if (type === 'audience') {
    if (store.username) {
      socket = new Socket('play', { id: store.id, audienceName: store.username })
    }
  } else if (type === 'contestant') {
    if (store.contestant) {
      socket = new Socket('play', { id: store.id, contestantName: store.contestant })
    }
  }
}, { immediate: true })
