<template>
  <BaseButtonMenu>
    <div class="d-flex gap-3">
      <CheerButton @pressed="onCheer">🎉</CheerButton>
      <CheerButton @pressed="onBoo">🤬</CheerButton>
      <CheerButton @pressed="onApplause">👏</CheerButton>
    </div>
  </BaseButtonMenu>
</template>

<script setup lang="ts">
import { confetti } from '../confetti.ts'
import { socket } from '../store.ts'
import BaseButtonMenu from './BaseButtonMenu.vue'
import CheerButton from './CheerButton.vue'

function onCheer() {
  confetti.addConfetti({ emojis: ['🎉'], confettiNumber: 5 })
  socket.send('CHEER', { type: 'CHEER' })
}
function onBoo() {
  confetti.addConfetti({ emojis: ['🤬'], confettiNumber: 5 })
  socket.send('CHEER', { type: 'BOO' })
}
function onApplause() {
  confetti.addConfetti({ emojis: ['👏'], confettiNumber: 5 })
  socket.send('CHEER', { type: 'APPLAUSE' })
}
</script>
