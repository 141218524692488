<template>
  <div class="c-modal modal d-block show" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>
