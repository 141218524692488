<template>
  <form class="c-join-menu d-grid gap-3" action="" @submit.prevent>
    <button type="button" class="btn btn-primary" @click="$emit('joincontestant')">Join as CONTESTANT<i>!</i></button>
    <button type="button" class="btn btn-info" @click="$emit('joinaudience')">Join as AUDIENCE<i>!</i></button>
  </form>
</template>

<script setup lang="ts">
defineEmits(['joincontestant', 'joinaudience'])
</script>
