<template>
  <form class="c-join-contestant-menu d-grid gap-3" @submit.prevent="onClickContinue">
    <div class="form-floating">
      <input id="c-join-contestant-menu-password" class="form-control" type="password" placeholder="password" v-model="password">
      <label for="c-join-contestant-menu-password">What's your password?</label>
    </div>
    <button type="button" class="btn btn-primary" @click="onClickContinue">Continue as CONTESTANT<i>!</i></button>
  </form>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { store } from '../store.ts'
import { Contestant, contestants } from '../contestants.ts'

const emit = defineEmits(['continue'])

const password = ref('')

const contestant = computed<Contestant | null>(() => {
  const encoded = btoa(password.value)
  return contestants.find(({ password }) => password === encoded) ?? null
})

function onClickContinue() {
  if (contestant.value == null) {
    alert('Sorry, that password is incorrect!')
    password.value = ''
    return
  }

  store.username = contestant.value.username
  store.contestant = contestant.value.username
  store.type = 'contestant'

  emit('continue')
}
</script>
