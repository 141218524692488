<template>
  <Modal>
    <Banner/>
    <div v-if="joining">
      <JoinMenu v-if="joinType == null" @joinaudience="joinType = 'audience'" @joincontestant="joinType = 'contestant'" />
      <JoinAudienceMenu v-if="joinType == 'audience'" />
      <JoinContestantMenu v-if="joinType == 'contestant'" />
    </div>
    <div v-else>
      <AudienceButtonMenu v-if="store.type === 'audience'" />
      <ContestantButtonMenu v-if="store.type === 'contestant'" />
    </div>
  </Modal>
</template>

<style>
body {
  background-color: transparent;
  transition: background 0.2s ease-in-out;
}
body.buzzer {
  background-image: none !important;
  background-color: var(--bs-danger) !important;
}
</style>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { store } from '../store.ts'
import Modal from './Modal.vue'
import Banner from './Banner.vue'
import JoinMenu from './JoinMenu.vue'
import JoinAudienceMenu from './JoinAudienceMenu.vue'
import JoinContestantMenu from './JoinContestantMenu.vue'
import ContestantButtonMenu from './ContestantButtonMenu.vue'
import AudienceButtonMenu from './AudienceButtonMenu.vue'

const joinType = ref<'audience' | 'contestant' | null>(null)
const joining = computed(() => {
  return store.type == null || !store.username
})
</script>
