<template>
  <div class="c-buzzer-button d-grid" :class="{ 'c-buzzer-button-pressed': pressed }">
    <button
      type="button"
      class="btn btn-outline-danger btn-lg"
      @click="onClick"
    ><h1><i>BZZZZZZZZT!!</i></h1></button>
  </div>
</template>

<style scoped>
.c-buzzer-button {
  transition: transform 0.015s ease-in-out;
  transform: scale(1.0);
}
.c-buzzer-button-pressed {
  transform: scale(0.8);
}

.c-buzzer-button > button {
  min-height: 100px;
}
.c-buzzer-button.c-buzzer-button-pressed > button {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}
</style>

<script setup lang="ts">
import { ref } from 'vue'

const emit = defineEmits(['pressed', 'unpressed'])

const pressed = ref(false)

function onPressed() {
  pressed.value = true
  emit('pressed')
  document.body.classList.add('buzzer')
}
function onUnpressed() {
  pressed.value = false
  emit('unpressed')
  document.body.classList.remove('buzzer')
}

function onClick(event: MouseEvent) {
  if (!pressed.value) {
    onPressed()
    setTimeout(onUnpressed, 3000)
  }
}
</script>
