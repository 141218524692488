<template>
  <button
    type="button"
    class="c-cheer-button btn btn-outline-info btn-lg w-100"
    @click="emit('pressed')"
  ><h1><slot/></h1></button>
</template>

<style scoped>
.c-cheer-button {
  transform: scale(1.0);
}
.c-cheer-button:active {
  transform: scale(0.95);
}
</style>

<script setup lang="ts">
const emit = defineEmits(['pressed'])
</script>
