
export interface Contestant {
  readonly username: string
  readonly password: string
}

export const contestants: Contestant[] = [
  { username: 'Eman'   , password: 'eWlwcGVl'         },
  { username: 'Ez'     , password: 'c3RhcmJ1Y2tz'     },
  { username: 'Yuty'   , password: 'aG9yc2U='         },
  { username: 'Mabbs'  , password: 'ZGVlcg=='         },
  { username: 'Kal'    , password: 'aG9yZXM='         },
  { username: 'Stringy', password: 'Z290dGFnb2Zhc3Q=' },
  { username: 'Miles'  , password: 'b2trb3J1bGVz'     },
  { username: 'Fringe' , password: 'bWNkb25hbGRz'     },
]

export function findContestant(username: string): Contestant | null {
  return contestants.find((contestant) => contestant.username.localeCompare(username) === 0) ?? null
}
